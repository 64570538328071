// 年月日时分秒
export const nowDateTime = function () {
  // 获取当前时间
  let date = new Date()
return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()}:${date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()}` 
}
export const getNowYear = () => {
  let date = new Date()
  return `${date.getFullYear()}`;
}

// 年月日 时分秒为0 的前7天
export const nowDay = function () {
  let date = new Date()
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
}
// 当前日期的前七天
export const frontDay = function (n, isTime = true) {
  var d = new Date();
  var year = d.getFullYear();
  var mon = d.getMonth() + 1;
  var day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    }
    else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  var s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
  if (isTime) {
    return `${s} 00:00:00`;
  }
  else {
    return s
  }
}
// 当前日期的当天截至
export const frontNowDay = function (n, isTime = true) {
  var d = new Date();
  var year = d.getFullYear();
  var mon = d.getMonth() + 1;
  var day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    } else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  var s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);

  if (isTime) {
    return `${s} 23:59:59`;
  }
  else {
    return s
  }
}

export const checkTime = (str, hours) => {
  // str时间字符串，hours小时数，
  // 判断是否在hours个小时后
  let date1 = new Date(str)
  let date2 = new Date(); //获取当前时间对象
  var num = hours * 60 * 60 * 1000; //hours个小时的毫秒数
  var cha = date2.getTime() - date1.getTime(); //两个时间的毫秒差
  if (cha < 0 && cha < -num) {
    return true
  } else {
    return false
  }
}